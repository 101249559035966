import Icon from '@atoms/Icon/Icon';
import { PANEL_IDS } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import { MenuState } from '@type-declarations/menu';
import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './SidePanel.module.scss';

interface MenuToggleProps {
  menuState: MenuState;
  toggleMenu: (value: MenuState) => void;
  toggleLabel: string;
}

const MenuToggle = forwardRef<HTMLButtonElement, MenuToggleProps>(
  ({ toggleMenu, toggleLabel, menuState }, ref) => {
    const t = useTranslations();

    return (
      <button
        ref={ref}
        className={clsx(
          styles.panelItem,
          menuState === 'main' && styles.active
        )}
        type="button"
        onClick={() => toggleMenu('main')}
        aria-label={toggleLabel}
        aria-expanded={menuState === 'main' ? 'true' : 'false'}
        aria-controls={PANEL_IDS.main}
      >
        <Icon icon="MENU" className={styles.icon} />
        <span>{t.main}</span>
      </button>
    );
  }
);

MenuToggle.displayName = 'MenuToggle';

export default MenuToggle;
