import WrapInLink from '@atoms/Button/WrapInLink';
import Icon from '@atoms/Icon/Icon';
import SaveButton from '@atoms/SaveButton/SaveButton';
import Tag from '@atoms/Tags/Tag';
import { DETAIL_PAGES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import HighlightedCorner from '@svg/highlighted-card-corner.svg';
import Logo from '@svg/logo.svg';
import { Locale } from '@type-declarations/locale';
import { Image as ImageType, ImageAlignment } from '@type-declarations/media';
import { StoreModifier } from '@type-declarations/modifier';
import {
  Frequency,
  PreprDate,
  RouteType,
  Tag as TagType,
} from '@type-declarations/prepr';
import { PreprPage } from '@type-declarations/preprPage';
import datesToLocalizedString from '@utils/datesToLocalizedString';
import { formatDistance } from '@utils/formatDistance';
import { findHighlightedTag } from '@utils/getHighlightedTag';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { useMemo } from 'react';

import styles from './Card.module.scss';

const translations: {
  nl: {
    [key: (typeof DETAIL_PAGES)[number]]: string;
  };
  en: {
    [key: (typeof DETAIL_PAGES)[number]]: string;
  };
} = {
  nl: {
    MainCategory: 'Pagina',
    SubCategory: 'Pagina',
    SubSubCategory: 'Pagina',
    ContentPage: 'Pagina',
    EventPage: 'Evenement',
    LocationPage: 'Locatie',
    NewsPage: 'Artikel',
    RoutePage: 'Route',
    walking: 'Wandel route',
    bicycle: 'Fiets route',
    ZigzagCampaignPage: 'Campagne',
    LandingPage: 'Pagina',
  },
  en: {
    MainCategory: 'Page',
    SubCategory: 'Page',
    SubSubCategory: 'Page',
    ContentPage: 'Page',
    EventPage: 'Event',
    LocationPage: 'Location',
    NewsPage: 'Article',
    RoutePage: 'Route',
    walking: 'Walking route',
    bicycle: 'Bicycle route',
    ZigzagCampaignPage: 'Campaign',
    LandingPage: 'Page',
  },
} as const;

const getSubtitle = ({
  subtitle,
  typename,
  dates,
  frequency,
  distance,
  locale,
  routeType,
}: {
  subtitle?: string;
  typename?: PreprPage;
  dates?: PreprDate[];
  frequency?: Frequency;
  distance?: number;
  locale: Locale;
  routeType?: RouteType;
}): string | JSX.Element => {
  if (subtitle) {
    return subtitle;
  }

  if (typename === 'EventPage' && dates?.length) {
    return datesToLocalizedString({ dates, locale, frequency });
  }

  if (typename === 'RoutePage' && distance) {
    return `${translations[locale][routeType || 'walking']} - ${formatDistance(
      distance
    ).toString()}`;
  }

  return translations[locale][typename!] || '';
};

interface Props {
  imageSizeBreakpoints?: ('md@from-md' | 'lg@from-lg')[];
  showSaveButton?: boolean;
  showChevron?: boolean;
  imageSize?: 'sm' | 'md' | 'lg';
  routeType?: RouteType;
  alignment?: ImageAlignment;
  className?: string;
  showType?: boolean;
  typename?: PreprPage;
  subtitle?: string;
  distance?: number;
  dates?: PreprDate[];
  frequency?: Frequency;
  image?: ImageType;
  sizes?: string;
  title: string;
  tags?: TagType[];
  href: string;
  id: string;
  modifier?: StoreModifier | 'flat';
  noTransform?: boolean;
  isPopup?: boolean;
  noBlur?: boolean;
}

function Card({
  imageSizeBreakpoints = [],
  showSaveButton,
  showChevron,
  imageSize = 'md',
  className,
  routeType,
  typename,
  alignment,
  id,
  showType,
  subtitle,
  sizes = '(max-width: 640px) 100vw, (max-width: 768px) 50vw, 33vw',
  distance,
  dates,
  frequency,
  image,
  title,
  href,
  tags,
  modifier,
  noTransform,
  isPopup,
  noBlur = false,
}: Props) {
  const {
    store: { locale },
  } = useStore();

  const t = useTranslations();
  const highlightedTag = useMemo(() => findHighlightedTag(tags || []), [tags]);

  return (
    <article
      className={clsx(
        styles.card,
        className,
        modifier && styles[modifier],
        noTransform && styles.noTransform,
        isPopup && styles.isPopup,
        highlightedTag?.theme && styles[highlightedTag.theme]
      )}
    >
      {highlightedTag && (
        <div className={styles.highlightedCorner}>
          {highlightedTag?.icon && (
            <Icon className={styles.cornerIcon} icon={highlightedTag?.icon} />
          )}
          <HighlightedCorner className={styles.corner} />
        </div>
      )}

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <WrapInLink
            href={href}
            className={clsx(styles.link, 'u-umbrella-link')}
            attributes={{ title }}
          >
            <h1 className={styles.title}>{title}</h1>
            {showChevron && (
              <Icon className={styles.chevron} icon="NAV_ARROW_RIGHT" />
            )}
          </WrapInLink>
          {(showType ||
            !!subtitle ||
            typename === 'EventPage' ||
            typename === 'RoutePage') && (
            <p className={styles.subtitle}>
              {getSubtitle({
                subtitle,
                locale,
                typename,
                dates,
                distance,
                routeType,
                frequency,
              })}
            </p>
          )}

          {modifier === 'flat' && (
            <div className={styles.fakeButton}>
              {t.readMore}
              <Icon icon="NAV_ARROW_RIGHT" className={styles.icon} />
            </div>
          )}
        </div>

        <div
          className={clsx(
            styles.imgContainer,
            styles[imageSize],
            imageSizeBreakpoints.map(breakpoint => styles[breakpoint])
          )}
        >
          {image?.url ? (
            <Image
              src={image.url}
              placeholder={noBlur ? undefined : 'blur'}
              blurDataURL={image.placeholder}
              sizes={sizes}
              alt=""
              objectFit="cover"
              objectPosition={alignment?.focusPoint || 'center center'}
              layout="fill"
              className={styles.image}
            />
          ) : (
            <div className={styles.placeholder}>
              <Logo />
            </div>
          )}

          {!!tags?.length && (
            <Tag title={tags[0].title} className={styles.tag} />
          )}
          {(showSaveButton || modifier === 'inStore') && (
            <SaveButton
              id={id}
              pageTitle={title}
              pageType={typename}
              className={styles.save}
            />
          )}
        </div>
      </div>
    </article>
  );
}

export default Card;
