import assetFragment from '@lib/fragments/asset-fragment';
import gql from 'graphql-tag';

import { addressFields } from './address-fragment';
import ctaFragment from './cta-fragment';
import { categoryFields, subSubCategoryQuery } from './menu-item-fragment';
import { tagFragment } from './tag-fragment';

export const eventItem = gql`
  fragment eventItem on EventPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    dates {
      from
      until
    }
    frequency
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const eventOverviewItem = gql`
  fragment eventOverviewItem on EventOverviewPage {
    typename: __typename
    id: _id
    title
    introText: intro_text
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    alignment {
      focusPoint: focus_point
    }
  }
  ${assetFragment}
`;

export const mapLocationItem = gql`
  fragment mapLocationItem on LocationPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    alignment {
      focusPoint: focus_point
    }
    category {
      ${subSubCategoryQuery}

      icon
      category {
        icon
      }
    }
    coordinates {
      latitude
      longitude
    }
    address {
      ${addressFields}
    }
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const locationItem = gql`
  fragment locationItem on LocationPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const locationOverviewItem = gql`
  fragment locationOverviewItem on LocationOverviewPage {
    typename: __typename
    id: _id
    title
    introText: intro_text
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    alignment {
      focusPoint: focus_point
    }
  }
  ${assetFragment}
`;

export const contentItem = gql`
  fragment contentItem on ContentPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const routeItem = gql`
  fragment routeItem on RoutePage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    distance
    routeType: route_type
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const routeOverviewItem = gql`
  fragment routeOverviewItem on RouteOverviewPage {
    typename: __typename
    id: _id
    title
    introText: intro_text
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    alignment {
      focusPoint: focus_point
    }
  }
  ${assetFragment}
`;

export const newsItem = gql`
  fragment newsItem on NewsPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    tags: content_tags {
      ${tagFragment}
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export const newsOverviewItem = gql`
  fragment newsOverviewItem on NewsOverviewPage {
    typename: __typename
    id: _id
    title
    introText: intro_text
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    alignment {
      focusPoint: focus_point
    }
  }
  ${assetFragment}
`;

export const mainCategoryItem = gql`
  fragment mainCategoryItem on MainCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
  }
  ${assetFragment}
`;

export const subCategoryItem = gql`
  fragment subCategoryItem on SubCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    category {
      ${categoryFields}
    }
  }
  ${assetFragment}
`;

export const subSubCategoryItem = gql`
  fragment subSubCategoryItem on SubSubCategory {
    typename: __typename
    id: _id
    slug: _slug
    title
    introText: intro_text
    alignment {
      focusPoint: focus_point
    }
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
    category {
      ${categoryFields}
      category {
        ${categoryFields}
      }
    }
  }
  ${assetFragment}
`;

export const zigzagCampaignItem = gql`
  fragment zigzagCampaignItem on ZigzagCampaignPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
  }
`;

export const landingItem = gql`
  fragment landingItem on LandingPage {
    typename: __typename
    id: _id
    slug: _slug
    title
    image {
      ...assetFields
      url(width: 640, format: "webp")
    }
  }
`;

export const overviewItemFields = `
  ... on NewsPage {
    ...newsItem
  }
  ... on NewsOverviewPage {
    ...newsOverviewItem
  }
  ... on LocationPage {
    ...locationItem
  }
  ... on LocationOverviewPage {
    ...locationOverviewItem
  }
  ... on EventPage {
    ...eventItem
  }
  ... on EventOverviewPage {
    ...eventOverviewItem
  }
  ... on RoutePage {
    ...routeItem
  }
  ... on RouteOverviewPage {
    ...routeOverviewItem
  }
  ... on ContentPage {
    ...contentItem
  }
  ... on MainCategory {
    ...mainCategoryItem
  }
  ... on SubCategory {
    ...subCategoryItem
  }
  ... on SubSubCategory {
    ...subSubCategoryItem
  }
  ... on ZigzagCampaignPage {
    ...zigzagCampaignItem
  }
  ... on LandingPage {
    ...landingItem
  }
`;

export const overviewItemsFragment = gql`
  ${locationItem}
  ${locationOverviewItem}
  ${newsItem}
  ${newsOverviewItem}
  ${eventItem}
  ${eventOverviewItem}
  ${routeItem}
  ${routeOverviewItem}
  ${contentItem}
  ${mainCategoryItem}
  ${subCategoryItem}
  ${subSubCategoryItem}
  ${zigzagCampaignItem}
  ${landingItem}
`;
