import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import useStore from '@hooks/useStore';
import SidePanel from '@molecules/SidePanel/SidePanel';
import { HeroVariant } from '@type-declarations/hero';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import styles from './Header.module.scss';
import LogoLink from './LogoLink';

interface Props {
  isCampaignPage?: boolean;
  heroVariant: HeroVariant | null;
}

export default function Header({ heroVariant, isCampaignPage }: Props) {
  const { ref, inView } = useInView({
    delay: 0,
  });

  const {
    store: { config: { mainMenu } = {}, page },
  } = useStore();

  const topLevelLinks = mainMenu?.filter(
    ({ internalLink, externalLink }) =>
      (internalLink && internalLink[0]) || externalLink
  );

  const hasImage = !!page && 'image' in page && !!page.image;

  return (
    <header
      className={clsx(
        styles.header,
        heroVariant && styles.hasHero,
        heroVariant === 'large' && styles.hasLargeHero,
        (!heroVariant || !hasImage) && styles.solid,
        heroVariant &&
          heroVariant !== 'search' &&
          !hasImage &&
          styles.hasHeroWithoutImage,
        heroVariant === 'search' && styles.search,
        isCampaignPage && styles.isCampaignPage
      )}
    >
      {/* Static TOP-BAR with logo en additional menu-items */}
      {!isCampaignPage && (
        <div className={styles.topBar}>
          <div ref={ref} className={styles.inViewRef} />
          <div className={clsx(styles.container, 'u-container-width')}>
            <LogoLink />

            {/* Some menu-items here (<ul>) */}
            {!!topLevelLinks?.length && (
              <ul className={clsx(styles.list, 'u-list-clean')}>
                {topLevelLinks.map(menuItem => (
                  <li key={menuItem.id}>
                    <NavigationItem item={menuItem} className={styles.link} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}

      {/* Fixed sidepanel (top-right) */}
      <SidePanel
        inView={inView}
        canExpand={!!heroVariant && hasImage}
        isCampaignPage={isCampaignPage}
      />
    </header>
  );
}
