import { BOOKMARK_COOKIE } from '@constants/constants';
import useFocusTrap from '@hooks/useFocusTrap';
import useStore from '@hooks/useStore';
import useStoreBookmarks from '@hooks/useStoreBookmarks';
import useTranslations from '@hooks/useTranslations';
import MainPanel from '@organisms/Header/MainPanel';
import { MenuState } from '@type-declarations/menu';
import { getCookie } from '@utils/clientCookies';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import BookmarksToggle from './BookmarksToggle';
import CollapsableList from './CollapsableList';
import MenuToggle from './MenuToggle';
import styles from './SidePanel.module.scss';

interface SidePanelProps {
  inView: boolean;
  canExpand: boolean;
  isCampaignPage?: boolean;
}

export default function SidePanel({
  inView,
  canExpand,
  isCampaignPage,
}: SidePanelProps) {
  const t = useTranslations();
  const [menuState, setMenuState] = useState<MenuState>(false);

  const ref = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(ref, toggleRef);
  const { asPath } = useRouter();
  const { setBookmarks, bookmarks } = useStoreBookmarks();
  const {
    store: { locale },
  } = useStore();

  const toggleMenu = (value: MenuState): void => {
    setMenuState(curVal => (curVal === value ? false : value));
  };

  const closeMenu = () => {
    setMenuState(false);
  };

  useEffect(() => {
    if (menuState) {
      toggleMenu(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, locale]);

  useEffect(() => {
    if (menuState) {
      enableFocusTrap();
    } else {
      disableFocusTrap();
    }

    return () => {
      disableFocusTrap();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuState]);

  useEffect(() => {
    const bm = JSON.parse(getCookie(BOOKMARK_COOKIE) || '[]');
    setBookmarks({ bookmarks: bm });
  }, [setBookmarks]);

  const closePanel = () => {
    setMenuState(false);
  };

  const toggleLabel = menuState === 'main' ? t.closeMenu : t.openMenu;

  return (
    <div ref={ref}>
      <div
        className={clsx(
          styles.sidePanel,
          !!menuState && styles.menuOpen,
          canExpand && inView && styles.inView
        )}
      >
        {/* Menu toggle */}
        {isCampaignPage ? (
          <BookmarksToggle
            toggleMenu={toggleMenu}
            menuState={menuState}
            bookmarks={bookmarks}
          />
        ) : (
          <MenuToggle
            ref={toggleRef}
            toggleMenu={toggleMenu}
            toggleLabel={toggleLabel}
            menuState={menuState}
          />
        )}

        {/* Collapsible list */}
        {!isCampaignPage && (
          <CollapsableList
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            menuState={menuState}
            bookmarks={bookmarks}
            locale={locale}
          />
        )}
      </div>

      <MainPanel menuState={menuState} closePanel={closePanel} />
    </div>
  );
}
