import Icon from '@atoms/Icon/Icon';
import { PANEL_IDS } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import { MenuState } from '@type-declarations/menu';
import clsx from 'clsx';

import styles from './SidePanel.module.scss';

interface BookmarksToggleProps {
  menuState: MenuState;
  toggleMenu: (value: MenuState) => void;
  bookmarks: string[];
}

export default function BookmarksToggle({
  menuState,
  toggleMenu,
  bookmarks,
}: BookmarksToggleProps) {
  const t = useTranslations();

  return (
    <button
      type="button"
      className={clsx(styles.panelItem, menuState === 'list' && styles.active)}
      onClick={() => toggleMenu('list')}
      aria-expanded={menuState === 'list' ? 'true' : 'false'}
      aria-controls={PANEL_IDS.list}
    >
      <span className={styles.iconContainer}>
        <Icon icon="BOOKMARK" className={styles.icon} />
        {bookmarks?.length > 0 && (
          <span className={styles.counter}>{bookmarks.length}</span>
        )}
      </span>
      <span>{t.list}</span>
    </button>
  );
}
