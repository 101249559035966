import Icon, { IconType } from '@atoms/Icon/Icon';
import { routeChangeStart } from '@atoms/NProgress/NProgress';
import { PANEL_IDS, ROUTES } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';
import { Locale } from '@type-declarations/locale';
import { MenuState } from '@type-declarations/menu';
import clsx from 'clsx';
import Link from 'next/link';

import styles from './SidePanel.module.scss';

interface CollapsableListProps {
  menuState: MenuState;
  toggleMenu: (value: MenuState) => void;
  closeMenu: () => void;
  bookmarks: string[];
  locale: Locale;
}

const options = [
  {
    key: 'search',
    icon: 'SEARCH',
  },
  {
    key: 'list',
    icon: 'BOOKMARK',
  },
  {
    key: 'map',
    icon: 'MAP',
  },
] as { key: MenuState; icon: IconType }[];

export default function CollapsableList({
  menuState,
  bookmarks,
  locale,
  toggleMenu,
  closeMenu,
}: CollapsableListProps) {
  const t = useTranslations();

  const handleLinkClick = () => {
    closeMenu();
    routeChangeStart();
  };

  return (
    <ul className={clsx(styles.list, 'u-list-clean')}>
      {options.map(({ key, icon }) => {
        if (!key) return null;

        if (key === 'search') {
          return (
            <li key={key}>
              <Link
                href={`/${locale}/${ROUTES.SearchPage[locale]}`}
                className={styles.panelItem}
                onClick={handleLinkClick}
              >
                <span className={styles.iconContainer}>
                  <Icon icon={icon} className={styles.icon} />
                </span>
                <span>{t.search}</span>
              </Link>
            </li>
          );
        }

        if (key === 'map') {
          return (
            <li key={key}>
              <Link
                href={`/${locale}/${ROUTES.MapPage[locale]}`}
                className={styles.panelItem}
                onClick={handleLinkClick}
              >
                <span className={styles.iconContainer}>
                  <Icon icon={icon} className={styles.icon} />
                </span>
                <span>{t.explore}</span>
              </Link>
            </li>
          );
        }

        return (
          <li key={key}>
            <button
              type="button"
              className={clsx(
                styles.panelItem,
                menuState === key && styles.active
              )}
              onClick={() => toggleMenu(key)}
              aria-expanded={menuState === key ? 'true' : 'false'}
              aria-controls={PANEL_IDS[key]}
            >
              <span className={styles.iconContainer}>
                <Icon icon={icon} className={styles.icon} />
                {key === 'list' && bookmarks?.length > 0 && (
                  <span className={styles.counter}>{bookmarks.length}</span>
                )}
              </span>
              <span>{t[key]}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}
