import { DEFAULT_LOCALE } from '@constants/constants';
import { Locale } from '@type-declarations/locale';
import { MenuItem } from '@type-declarations/menu';
import { Event, OverviewItem } from '@type-declarations/overviewItems';
import { Page } from '@type-declarations/page';
import { Category, ExploreTag } from '@type-declarations/prepr';
import { Footer } from '@type-declarations/sections';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface Store {
  preview?: boolean;
  locale: Locale;
  customerId: string;
  config: {
    footer: Footer | null;
    mainMenu: MenuItem[];
    secondaryMenu: MenuItem[];
  };
  showAccessibilityPanel?: boolean;
  page?: Page & {
    additionalSectionData?: {
      [key: string]: {
        ContentItems?: {
          items: OverviewItem[];
        };
        ExploreTags?: {
          items: ExploreTag[];
        };
        EventPages?: {
          items: Event[];
        };
      };
    };
    exploreTags: ExploreTag[]; // is used on Explore page
    category?: [Category] | [];
  };
  mostSearched: MenuItem[];
}

type Props = { store: Store } & PropsWithChildren;

// TODO: REMOVE ANY TYPE. Allow setStore.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StoreContext = createContext<
  Props & { setStore: Dispatch<SetStateAction<Store>> }
>({
  store: {
    preview: false,
    locale: DEFAULT_LOCALE,
    customerId: '',
    config: {
      footer: null,
      mainMenu: [],
      secondaryMenu: [],
    },
    page: undefined,
    mostSearched: [],
    showAccessibilityPanel: false,
  },
  setStore: () => {},
});

// Export the provider as we need to wrap the entire app with it
export function StoreProvider({ store: storeProps, children }: Props) {
  const [store, setStore] = useState<Store>({
    ...storeProps,
    showAccessibilityPanel: false,
  });

  useEffect(() => {
    setStore(s => ({
      ...s,
      preview: storeProps.preview,
      page: storeProps.page,
      config: storeProps.config,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeProps.preview, storeProps.page]);

  // Make the provider update only when it should.
  // We only want to force re-renders if the store state changes.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly!
  // we want to keep things very performant.
  const memoedValue = useMemo(() => ({ store, setStore }), [store, setStore]);

  return (
    <StoreContext.Provider value={memoedValue}>
      {children}
    </StoreContext.Provider>
  );
}

// Let's only export the `useStore` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useStore() {
  return useContext(StoreContext);
}
